import { useEffect, useState } from 'react';
import { KeywordStrength, Stats, StatsType } from '../types/types';
import { KeywordsCollection, StatsCollection } from '../collections';
import { getFormattedDateString } from 'src/utils/app/string';
import { fetchCollectionData } from '../firebase-utils';
import { calculateOverallStrength } from 'src/utils/app/array';

type Props = {
  date: string;
  locationId?: string;
  type: StatsType;
};

interface KeywordDataResponse {
  keywordsStats: Stats[];
  strength?: KeywordStrength;
}

const useKeywordStats = ({
  date,
  locationId,
  type,
}: Props): KeywordDataResponse => {
  const [keywordsStats, setKeywordsStats] = useState<Stats[]>([]);
  const [strength, setStrength] = useState<KeywordStrength>();

  useEffect(() => {
    if (!locationId) {
      return;
    }

    let collectionId = '';
    if (type == StatsType.overall) {
      collectionId = `${StatsCollection}/${locationId}/${type}`;
    } else {
      const formattedDate = getFormattedDateString(date, type);
      collectionId = `${StatsCollection}/${locationId}/${type}/${formattedDate}/${KeywordsCollection}`;
    }

    const { unsubscribe } = fetchCollectionData<Stats>(
      collectionId,
      (data: Stats[]) => {
        const filteredStats = data.filter(item => item.enabled);
        setKeywordsStats(filteredStats);
        setStrength(calculateOverallStrength(filteredStats));
      },
    );

    return () => unsubscribe?.();
  }, [locationId, date]);

  return { keywordsStats, strength };
};

export { useKeywordStats };
