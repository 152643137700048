import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { select, fullWidthSelect, text } from './styles';
import { useTranslation } from 'src/text';
import { BusinessLocation } from 'src/api/types/business-types';
import { AppStateID, useAppAuth } from 'src/context/use-app-auth';
import { useWindowDimensions } from 'src/utils/ui/dimensions';

const LocationSelect: React.FC = () => {
  const { dashboardLabels } = useTranslation();
  const { location, updateState, user } = useAppAuth();
  const { isMobile } = useWindowDimensions();
  const locations: BusinessLocation[] = user?.locations ?? [];

  const handleLocationSelection = (
    _event: React.SyntheticEvent<Element, Event>,
    selectedLocation: BusinessLocation | null,
  ) => {
    if (selectedLocation) {
      updateState(AppStateID.Location, selectedLocation);
    }
  };

  return (
    <Autocomplete<BusinessLocation>
      sx={isMobile ? [fullWidthSelect, text] : [select, text]}
      options={locations}
      getOptionLabel={option => option.title}
      value={location ?? null}
      onChange={handleLocationSelection}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderInput={params => (
        <TextField {...params} label={dashboardLabels.businessLocation} />
      )}
    />
  );
};

export { LocationSelect };
