import { CSSProperties } from 'styled-components';

const select: CSSProperties = {
  width: 300,
};

const fullWidthSelect: CSSProperties = {
  width: '100%',
  margin: 2,
};

const text: CSSProperties = {
  fontFamily: 'Roboto',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
};

export { text, select, fullWidthSelect };
