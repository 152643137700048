import { SxProps } from '@mui/material';
import { brand } from 'src/themes/palette';

const keywordDropdown: SxProps = {
  width: 300,
  marginTop: 2,
  marginRight: 2,
};

const mapsContainer: SxProps = {
  width: '65%',
  height: 400,
  marginTop: 2,
  borderRadius: 2,
  border: `1px solid ${brand.outline}`,
};

const title: SxProps = {
  marginTop: 2,
};

const progressContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
};

export { title, keywordDropdown, mapsContainer, progressContainer };
