import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useAppAuth } from 'src/context/use-app-auth';
import { StatsType } from 'src/api/types/types';
import { useKeywordStats } from 'src/api/hooks/use-keyword-stats';
import { listItem } from '../styles';
import { useAddData } from 'src/api/hooks/use-add-data';
import { useTranslation } from 'src/text';
import { KeywordsInfoComponent } from '../top-info';
import KeywordList from './keywords-list';
import KeywordGrid from './keywords-grid';
import { useWindowDimensions } from 'src/utils/ui/dimensions';
import { MapComponent } from '../../map';
import { alertLabels } from 'src/text/locales/default-en';

const KeywordsStatsList: React.FC = () => {
  const { date, location } = useAppAuth();
  const { isMobile } = useWindowDimensions();
  const { keywordLabels } = useTranslation();
  const { keywordsStats, strength } = useKeywordStats({
    date,
    locationId: location?.key,
    type: StatsType.overall,
  });
  console.log(location?.key);

  const keywordsStatsData = useMemo(() => {
    if (keywordsStats.length > 0) {
      return keywordsStats;
    }

    return [
      {
        key: undefined,
        title: keywordLabels.processingKeywords,
        subtitle: keywordLabels.researchKeywords,
        strength: 0,
        movement: 0,
        overallMovement: 0,
        competitorsCount: 0,
        enabled: true,
      },
    ];
  }, [keywordsStats]);

  const { deleteKeyword } = useAddData();

  const handleDeleteKeyword = async (index: number) => {
    if (!location) {
      return;
    }

    const stats = keywordsStats[index];
    const message = alertLabels.deleteKeyword(stats.title);

    if (window.confirm(message)) {
      deleteKeyword({
        key: stats.key ?? '',
        locationId: location?.key ?? '',
      });
    }
  };

  return (
    <>
      <KeywordsInfoComponent keywordStrength={strength} />
      <Box mt={2}>
        {isMobile ? (
          <KeywordList
            isEmptyData={keywordsStats.length === 0}
            keywordsStatsData={keywordsStatsData}
            keywordLabels={keywordLabels}
            handleDeleteKeyword={handleDeleteKeyword}
            listItem={listItem}
          />
        ) : (
          <KeywordGrid
            isEmptyData={keywordsStats.length === 0}
            keywordsStatsData={keywordsStatsData}
            keywordLabels={keywordLabels}
            handleDeleteKeyword={handleDeleteKeyword}
            listItem={listItem}
          />
        )}
      </Box>
      <MapComponent data={keywordsStatsData} />
    </>
  );
};

export { KeywordsStatsList };
