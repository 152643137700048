import { StatsType } from 'src/api/types/types';
import { formatDistanceToNow, Locale } from 'date-fns';
import { enUS, es, it } from 'date-fns/locale';

function getDateString(date: Date = new Date()) {
  return date.toLocaleDateString('en-US');
}

const generateKeywordId = async (value: string) => {
  const text = value.toLowerCase().trim();
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // Convert hash to a Base36 number
  const hashHex = hashArray
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
  return BigInt('0x' + hashHex).toString(36); // Convert hex to base36
};

function getFormattedDateString(dateStr: string, type: StatsType) {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  if (type === StatsType.daily) {
    return `${day}-${month}-${year}`;
  } else if (type === StatsType.monthly) {
    return `${month}-${year}`;
  } else {
    return `${year}`;
  }
}

function getRelativeTime(createTime: string, localeString: string): string {
  const date = new Date(createTime);
  let locale: Locale;

  switch (localeString) {
    case 'it':
      locale = it;
      break;
    case 'es':
      locale = es;
      break;
    default:
      locale = enUS;
  }

  return formatDistanceToNow(date, { addSuffix: true, locale });
}

export {
  getDateString,
  getFormattedDateString,
  getRelativeTime,
  generateKeywordId,
};
