import React, { useEffect, useState } from 'react';
import { AdvancedMarker, APIProvider, Map } from '@vis.gl/react-google-maps';
import { useAppAuth } from 'src/context/use-app-auth';
import { Stats } from 'src/api/types/types';
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { chartLabels, keywordLabels } from 'src/text/locales/default-en';
import {
  keywordDropdown,
  mapsContainer,
  title,
  progressContainer,
} from './styles';
import mapStyles from './mapStyle';
import { useMapKeywordStats } from 'src/api/hooks/use-map-keyword';
import { MarkerIcon } from './marker';
import { getColorCode, mergeStyles } from 'src/utils/ui/merge-styles';
import { useWindowDimensions } from 'src/utils/ui/dimensions';

interface MapProps {
  data: Stats[];
}

const MapComponent: React.FC<MapProps> = ({ data }) => {
  const { location } = useAppAuth();
  const [keyword, setKeyword] = useState<string | undefined>();

  const { isMobile } = useWindowDimensions();

  const { keywordsStats, loading } = useMapKeywordStats({
    keyword,
    locationId: location?.key,
  });

  const [center, setCenter] = useState({
    lat: location?.latitude ?? 0,
    lng: location?.longitude ?? 0,
  });

  useEffect(() => {
    if (location) {
      setCenter({
        lat: location?.latitude ?? 0,
        lng: location?.longitude ?? 0,
      });
      setKeyword(undefined);
    }
  }, [location]);

  const renderMarkers = () => {
    return keywordsStats.map(loc => {
      const keywordStrength = loc.stats.strength ?? 0;
      const colorCode = getColorCode(keywordStrength);
      return (
        <AdvancedMarker
          key={`${loc.location.latitude}-${loc.location.longitude}`}
          position={{
            lat: loc.location.latitude,
            lng: loc.location.longitude,
          }}>
          <MarkerIcon
            size={30}
            fill={colorCode}
            text={`${keywordStrength > 20 ? `${keywordStrength}+` : keywordStrength}`}
          />
        </AdvancedMarker>
      );
    });
  };

  return (
    <APIProvider apiKey={process.env.REACT_APP_FIREBASE_API_KEY ?? ''}>
      <Typography sx={title} variant="h3">
        {keywordLabels.mapTitle}
      </Typography>

      <Box sx={progressContainer}>
        <TextField
          select
          value={keyword}
          onChange={event => setKeyword(event.target.value)}
          label={chartLabels.selectKeyword}
          sx={keywordDropdown}>
          {data.map(option => (
            <MenuItem key={option.key} value={option.key}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>

        {loading && <CircularProgress size={40} color="secondary" />}
      </Box>

      {keyword && (
        <Box
          sx={mergeStyles(
            mapsContainer,
            isMobile ? { width: '100%' } : undefined,
          )}>
          <Map
            mapId={'22a815bb71fac4df'}
            minZoom={4}
            defaultZoom={12}
            styles={mapStyles}
            defaultCenter={center}
            disableDefaultUI={true}
            gestureHandling={'greedy'}
            style={{ width: '100%', height: '100%' }}>
            {renderMarkers()}
          </Map>
        </Box>
      )}
    </APIProvider>
  );
};

export { MapComponent };
