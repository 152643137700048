import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  infoContainer,
  strengthContainer,
  strengthContainerMobile,
  strengthLabel,
} from './styles';
import { AppStateID, useAppAuth } from 'src/context/use-app-auth';
import { generateKeywordId, getDateString } from 'src/utils/app/string';
import { useAddData } from 'src/api/hooks/use-add-data';
import { KeywordStrength, Role, StatsType } from 'src/api/types/types';
import { AddFieldComponent } from 'src/components/add-field';
import { conditionalRender } from 'src/utils/ui/conditional-render';
import { useTranslation } from 'src/text';
import { useWindowDimensions } from 'src/utils/ui/dimensions';
import { t3 } from 'src/themes/custom';

type Props = {
  keywordStrength?: KeywordStrength;
};

const KeywordsInfoComponent: React.FC<Props> = ({ keywordStrength }) => {
  const { addKeyword } = useAddData();
  const { isMobile } = useWindowDimensions();
  const { keywordLabels } = useTranslation();
  const { user, location, updateState } = useAppAuth();
  const isAddingKeywordsAllowed = user?.role === Role.admin;

  const handleAddPressed = async (value: string) => {
    if (!user || !location || !value) {
      return;
    }

    const documentId = await generateKeywordId(value);
    // We creates a single documentId and then uses that document id to add to other places
    await addKeyword({
      keyword: value,
      locationId: location.key,
      statsType: StatsType.daily,
      documentId,
    });

    await addKeyword({
      keyword: value,
      locationId: location.key,
      statsType: StatsType.monthly,
      documentId,
    });
    await addKeyword({
      keyword: value,
      locationId: location.key,
      statsType: StatsType.yearly,
      documentId,
    });

    await addKeyword({
      keyword: value,
      locationId: location.key,
      statsType: StatsType.overall,
      documentId,
    });

    await addKeyword({
      keyword: value,
      locationId: location.key,
      documentId,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDateChange = (newDate: Date) => {
    updateState(AppStateID.Date, getDateString(newDate));
  };

  const strength = keywordStrength ? keywordLabels[keywordStrength] : '--';
  const strStyle = keywordStrength
    ? strengthLabel[keywordStrength]
    : strengthLabel.none;

  return (
    <Box mt={8}>
      <Box sx={infoContainer}>
        <Box>
          <Box sx={isMobile ? strengthContainerMobile : strengthContainer}>
            <Typography style={strStyle} variant="h3">
              {strength}
            </Typography>
            <Typography sx={t3}>{keywordLabels.overallStrength}</Typography>
          </Box>
        </Box>
        {/*!isMobile && (
          <SimplyDatePicker
            onChange={handleDateChange}
            disableFuture
            date={date}
            label={genericLabels.month}
          />
        )*/}
      </Box>
      <Typography mt={2} variant="h3">
        {keywordLabels.rankings}
      </Typography>
      <>
        {conditionalRender(
          isAddingKeywordsAllowed,
          <Box width={isMobile ? '100%' : '50%'}>
            <AddFieldComponent
              allowSpaces
              label={keywordLabels.typeKeyword}
              onAdd={handleAddPressed}
            />
          </Box>,
        )}
        <Typography mt={3} width={isMobile ? '100%' : '50%'} sx={t3}>
          {keywordLabels.description}
        </Typography>
      </>
    </Box>
  );
};

export { KeywordsInfoComponent };
